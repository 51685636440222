(() => {
  // ns-hugo:/Users/heiko/Library/Caches/hugo_cache/modules/filecache/modules/pkg/mod/github.com/!hugo!blox/hugo-blox-builder/modules/blox-bootstrap/v5@v5.9.7/assets/js/wowchemy-theming.js
  var body = document.body;
  function getThemeMode() {
    return parseInt(localStorage.getItem("wcTheme") || 2);
  }
  function canChangeTheme() {
    return Boolean(window.wc.darkLightEnabled);
  }
  function initThemeVariation() {
    if (!canChangeTheme()) {
      console.debug("User theming disabled.");
      return {
        isDarkTheme: window.wc.isSiteThemeDark,
        themeMode: window.wc.isSiteThemeDark ? 1 : 0
      };
    }
    console.debug("User theming enabled.");
    let isDarkTheme;
    let currentThemeMode = getThemeMode();
    console.debug(`User's theme variation: ${currentThemeMode}`);
    switch (currentThemeMode) {
      case 0:
        isDarkTheme = false;
        break;
      case 1:
        isDarkTheme = true;
        break;
      default:
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
          isDarkTheme = true;
        } else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
          isDarkTheme = false;
        } else {
          isDarkTheme = window.wc.isSiteThemeDark;
        }
        break;
    }
    if (isDarkTheme && !body.classList.contains("dark")) {
      console.debug("Applying Hugo Blox Builder dark theme");
      document.body.classList.add("dark");
    } else if (!isDarkTheme && body.classList.contains("dark")) {
      console.debug("Applying Hugo Blox Builder light theme");
      document.body.classList.remove("dark");
    }
    return {
      isDarkTheme,
      themeMode: currentThemeMode
    };
  }

  // ns-params:@params
  var wcDarkLightEnabled = "minimal";
  var wcIsSiteThemeDark = false;

  // <stdin>
  window.wc = {
    darkLightEnabled: wcDarkLightEnabled,
    isSiteThemeDark: wcIsSiteThemeDark
  };
  if (window.netlifyIdentity) {
    window.netlifyIdentity.on("init", (user) => {
      if (!user) {
        window.netlifyIdentity.on("login", () => {
          document.location.href = "/admin/";
        });
      }
    });
  }
  initThemeVariation();
  window.PlotlyConfig = { MathJaxConfig: "local" };
  Object.keys(localStorage).forEach(function(key) {
    if (/^wc-announcement-/.test(key)) {
      document.documentElement.setAttribute("data-wc-announcement-status", "dismissed");
      console.debug("Hiding announcement...");
    }
  });
})();
